
import { Options, Vue } from 'vue-class-component'
import { Plan, PlanStatus, PlanSurvey, SaveType } from '@/models/plan'
import service from '@/utils/service'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'

@Options({
  components: {}
})
export default class PlanDetail extends Vue {
  plan: Plan = {
    name: '',
    id: undefined,
    content: '',
    cover: '',
    endTime: '',
    introduction: '',
    isAllowRetrain: false,
    planSurveyList: [],
    saveType: '',
    startTime: '',
    publishStatus: ''
  }

  planId = ''
  isSave = '0' // 是否保存   0不需要  1需要
  isOk = true
  isReSave = ''

  get isPublished () {
    return this.plan.publishStatus === 'PUBLISHED'
  }

  tapBack () {
    this.$router.push({ path: '/plans' })
  }

  mounted () {
    this.planId = this.$route.params.planId as string
    this.loadPlan()
  }

  tapPublish () {
    console.log('publish', this.plan)
    this.plan.saveType = SaveType.SAVE_PUBLISH
    // 发布的时候更新PlanStatus.IN_PROGRESS 保证计划执行任务发配
    // const isAfter = dayjs().isAfter(dayjs(this.plan.startTime))
    // const isBefore = dayjs().isBefore(dayjs(this.plan.endTime))
    // if (isAfter && isBefore) {
    //   this.plan.status = PlanStatus.IN_PROGRESS
    // }
    this.saveRequest()
  }

  tapSave () {
    console.log('save')
    this.plan.saveType = SaveType.SAVE_DRAFT
    this.saveRequest()
  }

  saveRequest () {
    if (!this.isOk) {
      return
    }
    this.isOk = false
    setTimeout(() => {
      this.isOk = true
    }, 2000)

    if (this.planId === '0') {
      // 新计划
      service.post('/mindup/plans', this.plan).then((res) => {
        this.isSave = '0'
        ElMessage({ type: 'success', message: '创建成功' })
        setTimeout(() => {
          if (this.plan.saveType === SaveType.SAVE_DRAFT) {
            this.planId = res.data.id
            this.$router.push({ path: `/plans/${this.planId}/management` })
          } else {
            this.$router.push({ path: '/plans' })
          }
        }, 500)
      })
    } else {
      // 旧计划
      service.post('/mindup/plans/' + this.planId, this.plan).then((res) => {
        this.isSave = '0'
        ElMessage({ type: 'success', message: '更新成功' })
        this.plan.publishStatus = res.data.publishStatus
        this.plan.status = res.data.status
        setTimeout(() => {
          this.$router.push({ path: `/plans/${this.planId}/management` })
        }, 500)
      })
    }
  }

  loadPlan () {
    if (this.planId === '0') {
      // 新计划
      this.plan = {
        name: '',
        id: undefined,
        content: '',
        cover: '',
        endTime: '',
        introduction: '',
        isAllowRetrain: false,
        planSurveyList: [],
        saveType: '',
        startTime: '',
        publishStatus: ''
      }
    } else {
      // 旧计划
      service.get('/mindup/plans/' + this.planId).then((res) => {
        // console.log('load plan res = >', res)
        this.plan = res.data
      })
    }
  }
}
