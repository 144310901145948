
import { Options, prop, Vue } from 'vue-class-component'
import SearchBar from '@/components/SearchBar.vue'
import { Emit, Model, Prop } from 'vue-property-decorator'
import moment from 'moment'
import service from '../utils/service'
import { MaterialType } from '../models/material'

@Options({
  components: {
    SearchBar
  }
})
export default class StandardExerciseMaterialPicker extends Vue {
  @Model('isShow', {
    type: Boolean,
    default: false
  })
  show!: boolean

  tableData = []
  currentRow: any = ''
  page = {
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    totalPages: 0
  }

  mounted () {
    this.loadData()
  }

  getDate (date?: string) {
    if (date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    }
    return ''
  }

  loadPageData () {
    this.loadData()
  }

  loadData (searchText = '') {
    if (searchText.length > 0) {
      this.page.currentPage = 1
    }
    service.get('/mindup/materials', {
      params: { materialType: MaterialType.STANDARD_EXERCISE, materialName: searchText, pageNum: this.page.currentPage, pageSize: this.page.pageSize }
    }).then((res) => {
      this.tableData = res.data.content
    })
  }

  rowClick (row: any) {
    console.log('row', row)
    this.currentRow = row
  }

  @Emit()
  tapSelect (row: any) {
    this.show = false
  }
}
