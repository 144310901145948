
import { Options, Vue } from 'vue-class-component'
import { AuthStore } from '@/store/modules/auth'
import { Role, User } from '@/models/user'

@Options({
  components: {}
})
export default class Layout extends Vue {
  links = [
    {
      name: 'Plans',
      title: '我的计划'
    },
    {
      name: 'Materials',
      title: '素材库'
    },
    {
      name: 'Dashboard',
      title: '数据看板'
    }, {
      name: 'Users',
      title: '用户管理'
    },
    {
      name: 'Home',
      title: '我的信息'
    }
  ]

  defaultAvatar = 'https://static.guanxintec.com/content/4dc2bcf3-cbf3-475e-8478-5fe538572c99.png'

  auth = AuthStore

  get activeIndex () {
    return this.allowedMenus.findIndex(link => link.name === this.$route.name || this.$route.path.startsWith(this.getRoute(link).path)) + ''
  }

  get allowedMenus () {
    return this.links.filter(link => this.getRoute(link).meta.roles.filter((role: string) => this.user?.roles?.indexOf(role) !== -1).length > 0)
  }

  get user (): User | undefined {
    return AuthStore.user || undefined
  }

  getName (role: string) {
    if (role === Role.teacher) {
      return '正念师'
    } else if (role === Role.admin) {
      return '管理员'
    } else if (role === Role.superAdmin) {
      return '超级管理员'
    }
  }

  jumpDoc () {
    window.location.href = 'https://ut8rm8915t.feishu.cn/docs/doccnTTu0OFtXUXkASMqr1M5yuc'
  }

  handleClick (role: string) {
    this.auth.setRole(role)
    window.location.reload()
  }

  goto (name: string) {
    this.$router.push({ name })
  }

  logout () {
    AuthStore.removeToken()
    this.$router.push({ name: 'Login' })
  }

  private getRoute (link: any): any {
    return this.$router.getRoutes().find(r => r.name === link.name)!
  }
}
