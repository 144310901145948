
import { Options, Vue } from 'vue-class-component'
import { format } from '@/utils/date'
import dayjs from 'dayjs'
import { ElCalendar, ElDialog } from 'element-plus'
import { Model, Watch } from 'vue-property-decorator'
import { Plan, PlanMaterial } from '@/models/plan'
import StandardExerciseMaterialPicker from '@/components/StandardExerciseMaterialPicker.vue'
import LifePracticeMaterialPicker from '@/components/LifePracticeMaterialPicker.vue'
import { MaterialType } from '@/models/material'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

Vue.registerHooks([
  'beforeRouteLeave'
])
@Options({
  components: {
    StandardExerciseMaterialPicker,
    LifePracticeMaterialPicker
  }
})
export default class PlanManagement extends Vue {
  @Model('plans', {
    type: Object,
    default: {}
  })
  plan!: Plan

  @Model('isSave', {
    type: String,
    default: '0'
  })
  isNeedSave = '0'

  mt = MaterialType
  startDate = dayjs(dayjs().format('YYYY-MM-DD'))
  days = 0
  selectedDate = ''
  showStandardMaterial = false
  showLifeMaterial = false
  endDate = this.startDate.add(this.days - 1, 'day')
  currentIndex = 0
  dialogVisible = false
  next!: NavigationGuardNext

  get startDateStr (): string {
    return this.startDate.format('YYYY-MM-DD')
  }

  get endDateStr (): string {
    return format(this.endDate)
  }

  @Watch('isSave')
  onChangeIsSave (newVal: any) {
    console.log('isSave ==== ', newVal)
    this.isNeedSave = newVal
  }

  mounted () {
    console.log('plan === ', this.plan)
    this.startDate = dayjs(this.plan.startTime)
    this.endDate = dayjs(this.plan.endTime)
    this.days = dayjs(this.endDate).diff(dayjs(this.startDate), 'day') + 1
    const list = this.plan.planContentGroupByDateList
    if (list) {
      this.plan.planContentGroupByDateList = []
      for (let i = 0; i < this.days; i++) {
        const currentDate = dayjs(this.startDate).add(i, 'day').format('YYYY-MM-DD')
        const findItem = list.find(i => i.date === currentDate)
        if (findItem) {
          this.plan.planContentGroupByDateList.push(findItem)
        } else {
          const item = {
            practiceList: [],
            date: currentDate
          }
          this.plan.planContentGroupByDateList.push(item)
        }
      }
    } else {
      this.plan.planContentGroupByDateList = []
      for (let i = 0; i < this.days; i++) {
        const currentDate = dayjs(this.startDate).add(i, 'day').format('YYYY-MM-DD')
        const item = {
          practiceList: [],
          date: currentDate
        }
        this.plan.planContentGroupByDateList.push(item)
      }
    }
  }

  scrollTo (index: number) {
    this.$nextTick(() => {
      const str = 'card-' + (index - 1)
      const dom = document.getElementById(str)
      console.log('dom ==== ', dom)
      if (dom) {
        dom.scrollIntoView({
          behavior: 'smooth', // 平滑过渡
          block: 'start' // 上边框与视窗顶部平齐。默认值
        })
      }
    })
  }

  calcDayNumber (day: string): number {
    return dayjs(day).diff(this.startDate, 'day') + 1
  }

  calcDateStr (number: number): string {
    return this.calcDate(number).format('YYYY-MM-DD')
  }

  calcDate (number: number): dayjs.Dayjs {
    return this.startDate.add(number - 1, 'day')
  }

  dateInRange (date: string): boolean {
    return this.startDateStr <= date && date <= this.endDateStr
  }

  onSelect (date: string) {
    console.log('date ==== ', date)
    this.selectedDate = date
    this.scrollTo(this.calcDayNumber(date))
  }

  onSelectTimeLine (dayNumber: number) {
    (this.$refs.calendar as (typeof ElCalendar)).pickDay(this.calcDate(dayNumber))
    this.selectedDate = this.calcDate(dayNumber).format('YYYY-MM-DD')
  }

  setCurrentIndex (index: number) {
    console.log('setCurrentIndex == ', index)
    this.currentIndex = index
  }

  standardSelected (row: any) {
    console.log('正式练习 == ', row)
    this.isNeedSave = '1'
    const list = this.plan.planContentGroupByDateList
    if (list) {
      const item = {
        materialId: row.id,
        materialCover: row.cover,
        materialContent: row.content,
        materialIntroduction: row.introduction,
        materialName: row.name,
        materialType: row.type,
        materialResourceType: row.resourceType,
        practiceNum: 1,
        isGrade: false,
        orderNum: list[this.currentIndex].practiceList.length
      }
      list[this.currentIndex].practiceList.push(item)
    }
    console.log('this.plan == ', this.plan)
  }

  lifeSelected (row: any) {
    console.log('生活练习 == ', row)
    this.isNeedSave = '1'
    const item = {
      materialId: row.id,
      materialCover: row.cover,
      materialContent: row.content,
      materialIntroduction: row.introduction,
      materialName: row.name,
      materialType: row.type,
      materialResourceType: row.resourceType
    }
    const list = this.plan.planContentGroupByDateList
    if (list) {
      list[this.currentIndex].practiceList.push(item)
    }
  }

  addStandardPractice (index: number) {
    console.log(index)
    this.showStandardMaterial = true
  }

  addLifePractice (index: number) {
    this.showLifeMaterial = true
  }

  tapPracticeDelete (index: number, pIndex: number) {
    this.isNeedSave = '1'
    const planContent = this.plan.planContentGroupByDateList
    if (planContent) {
      planContent[index].practiceList.splice(pIndex, 1)
    }
  }

  tapMoveUp (index: number, pIndex: number) {
    this.isNeedSave = '1'
    const planContent = this.plan.planContentGroupByDateList
    if (planContent) {
      const arr = planContent[index].practiceList
      if (pIndex !== 0) {
        this.swapArray(arr, pIndex - 1, pIndex)
      } else {
        alert('已经处于置顶，无法上移')
      }
    }
  }

  tapMoveDown (index: number, pIndex: number) {
    this.isNeedSave = '1'
    const planContent = this.plan.planContentGroupByDateList
    if (planContent) {
      const arr = planContent[index].practiceList
      if (pIndex < arr.length - 1) {
        this.swapArray(arr, pIndex + 1, pIndex)
      } else {
        alert('已经处于置底，无法下移')
      }
    }
  }

  swapArray (arr: PlanMaterial[], index1: number, index2: number) {
    arr[index1] = arr.splice(index2, 1, arr[index1])[0]
    arr[index1].orderNum = index1
    arr[index2].orderNum = index2
    return arr
  }

  copyPractice (index: number) {
    this.isNeedSave = '1'
    if (this.plan && this.plan.planContentGroupByDateList) {
      this.plan.planContentGroupByDateList[index].practiceList.forEach(item => {
        if (this.plan && this.plan.planContentGroupByDateList) {
          this.plan.planContentGroupByDateList[index + 1].practiceList.push(item)
        }
      })
      this.plan.planContentGroupByDateList[index + 1].practiceList.forEach(item => {
        item.id = undefined
      })
    }
    console.log(this.plan.planContentGroupByDateList)
  }

  beforeRouteLeave (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (this.isNeedSave === '1') {
      this.dialogVisible = true
      this.next = next
    } else {
      next()
    }
  }

  tapLeft () {
    this.dialogVisible = false
    this.next()
  }
}
