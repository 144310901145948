import dayjs from 'dayjs'
import moment from 'moment'

export const format = (date: dayjs.Dayjs): string => {
  return date.format('YYYY-MM-DD')
}

export function getDate (date?: string) {
  if (date) {
    const newDate = moment(date).format('YYYY-MM-DD hh:mm:ss')
    return newDate
  }
  return ''
}
