
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Plan, PlanSurvey } from '@/models/plan'
import moment from 'moment'
import { User } from '@/models/user'
import { PlanUser, SurveyStatistics } from '@/models/Statistics'

@Options({
  components: {}
})
export default class SummaryHeader extends Vue {
  @Prop() plan?: Plan
  @Prop() user?: PlanUser
  @Prop() survey?: SurveyStatistics
  @Prop() isHaveUser = true
  @Prop() isHaveSurvey = false
  @Prop() isExportCurrentPage = false

  tapSelectPlan () {
    console.log('plan')
    this.$emit('tapPlan')
  }

  tapSelectUser () {
    this.$emit('tapUser')
  }

  tapSelectSurvey () {
    this.$emit('tapSurvey')
  }

  getDateMMdd (date: string) {
    if (date) {
      const newDate = moment(date).format('YYYY-MM-DD')
      return newDate
    }
    return ''
  }

  tapExportInfo () {
    this.$emit('export')
  }
}
