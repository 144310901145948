import { MaterialContent, MaterialType } from '@/models/material'
import { SurveyQuestion } from '@/models/Survey'

export interface PlanSurvey {
  id?: number
  planId: number
  surveyId: number
  endTime: string
  startTime: string
  surveyTitle: string
  questionNum: number
  questions: SurveyQuestion[]
  timeArr?: string[]
  name: string
}

export interface PlanMaterial {
  id?: number
  isGrade?: boolean
  materialContent: string | MaterialContent[]
  materialCover: string
  materialId: string
  materialIntroduction: string
  materialName: string
  materialType: string
  planId?: number
  practiceNum?: number
  materialResourceType: string | undefined
  orderNum?: number
}

export interface PlanContent {
  practiceList: PlanMaterial[]
  date: string
}

export interface Plan {
  name: string
  id?: number
  content: any
  cover: string
  endTime: string
  introduction: string
  isAllowRetrain: boolean
  planSurveyList: PlanSurvey[]
  planContentGroupByDateList?: PlanContent[]
  saveType: string
  startTime: string
  timeArr?: string[]
  publishStatus: string
  participantNum?: string
  dayNum?: string
  status?: string
  teacherName?: string
  planDays?: number,
  isNeedAudit?: boolean
}

export enum PlanStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  FINISHED = 'FINISHED',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum SaveType {
  SAVE_DRAFT = 'SAVE_DRAFT',
  SAVE_PUBLISH = 'SAVE_PUBLISH'
}

export enum PlanStudentStatus {
  AUDITING = 'AUDITING',
  AUDIT_PASSED = 'AUDIT_PASSED',
  AUDIT_FAILED = 'AUDIT_FAILED'
}

export enum PlanStatusName {
  FINISHED = <any>'已结束',
  NOT_STARTED = <any>'未开始',
  IN_PROGRESS = <any>'进行中'
}
