
import { Options, Vue } from 'vue-class-component'
import { Survey, SurveyDimension, SurveyQuestion } from '@/models/Survey'
import service from '@/utils/service'
import { ElMessage, ElMessageBox } from 'element-plus'

@Options({
  components: {}
})
export default class SurveyMaterial extends Vue {
  tableData = []
  strTalble = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  isCheck = '0'
  formData: Survey = {
    id: 0,
    title: '',
    introduction: '',
    name: '',
    dimensions: [{ name: '', id: undefined }]
  }

  questions: SurveyQuestion[] = [
    {
      id: undefined,
      title: '',
      dimensionId: 0,
      surveyDimension: { name: '', id: undefined },
      content: [{
        id: 0,
        name: ''
      }],
      required: false,
      sortNumber: 0,
      type: 'SINGLE_CHOICE',
      scoreType: 'FORWARD_DIRECTION'
    }
  ]

  mounted () {
    console.log(this.$route.params)
    const surveyId = this.$route.params.SurveyId as string
    this.isCheck = this.$route.params.isCheck as string
    if (surveyId !== '0') {
      this.loadData(surveyId)
    }
  }

  tapDelete (index: number) {
    const dimension = this.formData.dimensions[index]
    const findIndex = this.questions.findIndex(q => q.surveyDimension.name === dimension.name)
    if (findIndex !== -1) {
      ElMessageBox({
        title: '无法删除',
        message: '该维度已经被引用,请删除所有引用后重试?'
      })
      return
    }
    this.formData.dimensions.splice(index, 1)
  }

  tapAdd (index: number) {
    this.formData.dimensions.splice(index + 1, 0, { name: '', id: undefined })
  }

  tapDeleteOption (index: number, qIndex: number) {
    this.questions[index].content.splice(qIndex, 1)
  }

  tapAddOption (index: number, qIndex: number) {
    this.questions[index].content.splice(qIndex + 1, 0, { id: 0, name: '' })
  }

  tapCopy (index: number) {
    const question = JSON.parse(JSON.stringify(this.questions[index]))
    question.id = undefined
    this.questions.push(question)
  }

  tapDeleteQuestion (index: number) {
    this.questions.splice(index, 1)
  }

  tapAddQuestion () {
    const item = {
      id: undefined,
      title: '',
      dimensionId: 0,
      surveyDimension: { name: '', id: undefined },
      content: [{
        id: 0,
        name: ''
      }],
      required: false,
      sortNumber: 0,
      type: 'SINGLE_CHOICE',
      scoreType: 'FORWARD_DIRECTION'
    }
    this.questions.push(item)
  }

  tapMoveUp (index: number) {
    if (index !== 0) {
      this.swapArray(this.questions, index - 1, index)
    } else {
      alert('已经处于置顶，无法上移')
    }
  }

  tapMoveDown (index: number) {
    if (index < this.questions.length - 1) {
      this.swapArray(this.questions, index + 1, index)
    } else {
      alert('已经处于置底，无法下移')
    }
  }

  swapArray (arr: SurveyQuestion[], index1: number, index2: number) {
    arr[index1] = arr.splice(index2, 1, arr[index1])[0]
    return arr
  }

  loadData (surveyId: string) {
    service.get('/mindup/surveys/' + surveyId).then((res) => {
      console.log(res.data)
      this.formData = res.data
      this.questions = res.data.questions
    })
  }

  tapSave () {
    for (let i = 0; i < this.questions.length; i++) {
      this.questions[i].sortNumber = i
    }

    if (this.formData.id === 0) {
      this.createSurvey()
    } else {
      this.updateSurvey(this.formData.id)
    }
  }

  createSurvey () {
    const para = {
      ...this.formData,
      questions: this.questions
    }

    service.post('/mindup/surveys', para).then((res) => {
      ElMessage({ type: 'success', message: '创建成功' })
      this.formData = res.data
      this.questions = res.data.questions
    })
  }

  updateSurvey (surveyId: number) {
    const para = {
      ...this.formData,
      questions: this.questions
    }
    service.post('/mindup/surveys/' + surveyId, para).then((res) => {
      ElMessage({ type: 'success', message: '更新成功' })
    })
  }
}
