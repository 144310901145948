
import { Options, Vue } from 'vue-class-component'
import PlanPicker from '@/components/PlanPicker.vue'
import PlanUserPicker from '@/components/PlanUserPicker.vue'
import SummaryHeader from '@/components/SummaryHeader.vue'
import { Plan, PlanSurvey } from '@/models/plan'
import service from '@/utils/service'
import PlanSurveyPicker from '@/components/PlanSurveyPicker.vue'
import { DimensionInfo, SurveyDimension, SurveyStatistics } from '@/models/Statistics'
import axios from 'axios'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: {
    PlanPicker,
    PlanUserPicker,
    SummaryHeader,
    PlanSurveyPicker
  }
})
export default class SurveySummary extends Vue {
  isPickerShow = false
  isShowSurvey = false
  currentPlan: Plan = {
    name: '',
    id: undefined,
    content: '',
    cover: '',
    endTime: '',
    introduction: '',
    isAllowRetrain: false,
    planSurveyList: [],
    saveType: '',
    startTime: '',
    publishStatus: ''
  }

  currentSurvey: SurveyStatistics = {
    createTime: '',
    createdBy: 0,
    endTime: '',
    id: 0,
    name: '',
    planId: 0,
    startTime: '',
    surveyId: 0
  }

  surveys: SurveyDimension[] = []
  dimensions: DimensionInfo[] = []
  indexArr: number[] = []
  contentArr: SurveyDimension[][] = []
  contentsArr: { userId: number, studentName?: string, name: string, contentArr: SurveyDimension[] }[] = []
  countArr: number[] = []

  tapSelectPlan () {
    console.log('plan')
    this.isPickerShow = true
  }

  tapSelectSurvey () {
    this.isShowSurvey = true
  }

  surveySelected (survey: SurveyStatistics) {
    console.log(survey)
    this.currentSurvey = survey
    if (this.currentSurvey && this.currentPlan && this.currentSurvey.id && this.currentPlan.id) {
      this.loadSurveyData(this.currentSurvey.id, this.currentPlan.id)
    }
  }

  planSelected (plan: Plan) {
    console.log('plan == ', plan)
    this.currentPlan = plan
  }

  loadSurveyData (planSuveyId: number, planId: number) {
    service.get('/mindup/statistics/plan-survey-statistic', {
      params: {
        planId: planId,
        planSurveyId: planSuveyId
      }
    }).then((res) => {
      this.surveys = res.data
      this.handleData()
    })

    service.get('/mindup/surveys/' + this.currentSurvey.surveyId + '/dimensions').then((res) => {
      this.dimensions = res.data
      this.handleData()
    })
  }

  handleData () {
    const indexArr: number[] = []
    const contentArr: SurveyDimension[][] = []
    this.surveys.forEach((item: SurveyDimension) => {
      const index = indexArr.indexOf(item.userId)
      if (index === -1) {
        indexArr.push(item.userId)
        contentArr.push([item])
      } else {
        contentArr[index].push(item)
      }
    })
    console.log('indexArr == ', indexArr)
    console.log('contentArr == ', contentArr)
    this.indexArr = indexArr
    this.contentArr = contentArr
    this.contentsArr = []
    for (let i = 0; i < this.indexArr.length; i++) {
      const userId = this.indexArr[i]
      const userName = this.contentArr[i][0].user.name
      const studentName = this.contentArr[i][0].studentName
      const contentArr = this.contentArr[i]
      const item = { userId: userId, name: userName, studentName: studentName, contentArr: contentArr }
      this.contentsArr.push(item)
    }

    console.log('contentsArr == ', this.contentsArr)
  }

  getValues (contentArr: SurveyDimension[], dimensionId: number, index: number) {
    let str = 0
    contentArr.forEach((item: SurveyDimension) => {
      if (item.surveyDimensionId === dimensionId) {
        str = item.surveyDimensionValue
      }
    })
    this.countArr[index] = this.countArr[index] + str
    return str
  }

  getAllValues (contentArr: SurveyDimension[]) {
    let count = 0
    contentArr.forEach((item: SurveyDimension) => {
      count += item.surveyDimensionValue
    })
    return count
  }

  exportInfo () {
    const data = {
      planId: this.currentPlan.id,
      planSurveyId: this.currentSurvey.id
    }
    const url = '/mindup/statistics/plan-survey-statistic/export'
    this.downExcelAction(url, data)
  }

  downExcelAction (url: string, data: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const baseUrl = window.VUE_APP_BASE_API
    return axios({
      url: baseUrl + url,
      method: 'get',
      data: '',
      params: data,
      transformRequest: [function (data) {
        let ret = ''
        for (const it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: AuthStore.token + ''
      },
      responseType: 'blob'
    }).then((res) => {
      const blob = new Blob([res.data], { type: 'application/vnd.ms-excel    application/x-excel;charset=utf-8' })
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      const timestamp = new Date().getTime()
      downloadElement.download = timestamp + '.xls'
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    })
  }
}
