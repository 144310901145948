
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Plan, PlanStatus } from '@/models/plan'
import moment from 'moment'

@Options({
  components: {}
})
export default class PlanCard extends Vue {
  @Prop() plan!: Plan

  get statusName () {
    if (this.plan.status === PlanStatus.FINISHED) {
      return '已结束'
    } else if (this.plan.status === PlanStatus.IN_PROGRESS) {
      return '进行中'
    } else if (this.plan.status === PlanStatus.NOT_STARTED) {
      return '未开始'
    }
  }

  getDate (date: string) {
    if (date) {
      const newDate = moment(date).format('YYYY-MM-DD')
      return newDate
    }
    return ''
  }

  tapCopy () {
    this.$emit('copy')
  }

  tapDelete () {
    this.$emit('delete')
  }
}
