
import { Options, prop, Vue } from 'vue-class-component'
import SearchBar from '@/components/SearchBar.vue'
import { Emit, Model, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import service from '../utils/service'
import { Plan } from '@/models/plan'
import { SurveyStatistics } from '@/models/Statistics'

@Options({
  components: {
    SearchBar
  }
})
export default class PlanSurveyPicker extends Vue {
  @Model('isShow', {
    type: Boolean,
    default: false
  })
  show!: boolean

  tableData: SurveyStatistics[] = []
  currentRow: any = ''
  page = {
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    totalPages: 0
  }

  @Prop() plan?: Plan

  @Watch('plan')
  onChangePlan () {
    this.loadData()
  }

  getDate (date?: string) {
    if (date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    }
    return ''
  }

  loadPageData () {
    this.loadData()
  }

  loadData () {
    if (this.plan) {
      service.get('/mindup/plans/' + this.plan.id + '/plan-surveys').then((res) => {
        this.tableData = res.data
      })
    }
  }

  rowClick (row: any) {
    console.log(row)
    this.currentRow = row
    this.tapSelect(this.currentRow)
  }

  @Emit()
  tapSelect (row: any) {
    this.show = false
  }
}
