
import { Options, Vue } from 'vue-class-component'
import SearchBar from '@/components/SearchBar.vue'
import service from '@/utils/service'
import moment from 'moment'
import { ElMessage, ElMessageBox } from 'element-plus'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: {
    SearchBar
  }
})
export default class SurveyMaterial extends Vue {
  authStore = AuthStore

  page = {
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    totalPages: 0
  }

  tableData = []

  forms = {
    name: '',
    content: ''
  }

  handleClickEdit (row: any) {
    this.$router.push({ name: 'SurveyDetail', params: { SurveyId: row.id } })
  }

  handleClickDelete (row: any) {
    console.log('delete')
    ElMessageBox({
      title: '删除问卷',
      message: '删除问卷后不可找回,是否确定删除?',
      showCancelButton: true,
      callback: (action: string) => {
        if (action === 'confirm') {
          service.delete('/mindup/surveys/' + row.id).then((res) => {
            ElMessage({ type: 'success', message: '删除成功' })
            this.loadData()
          })
        }
      }
    })
  }

  mounted () {
    this.loadData()
  }

  getDate (date?: string) {
    if (date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    }
    return ''
  }

  loadPageData () {
    this.loadData()
  }

  loadData (searchText = '') {
    if (searchText.length > 0) {
      this.page.currentPage = 1
    }
    service.get('/mindup/surveys', {
      params: { surveyName: searchText, pageNum: this.page.currentPage, pageSize: this.page.pageSize, sort: '-createTime', isQueryAll: AuthStore.isAdmin }
    }).then((res) => {
      this.tableData = res.data.content
    })
  }

  createSurvey () {
    this.$router.push({ name: 'SurveyDetail', params: { SurveyId: '0' } })
  }

  handlePublic (row: any) {
    service.post('/mindup/surveys/' + row.id + '/set-or-cancel-public').then((res) => {
      ElMessage({ type: 'success', message: '操作成功' })
    })
  }

  handleClickCheck (row: any) {
    this.$router.push({ name: 'SurveyDetail', params: { SurveyId: row.id, isCheck: '1' } })
  }
}
