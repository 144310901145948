
import { Options, Vue } from 'vue-class-component'
import SearchBar from '@/components/SearchBar.vue'
import { AuthStore } from '@/store/modules/auth'
import { ElMessage, ElMessageBox } from 'element-plus'
import service from '@/utils/service'
import { Material, MaterialContent, MaterialType } from '@/models/material'
import moment from 'moment'

@Options({
  components: {
    SearchBar
  }
})
export default class LifePracticeMaterial extends Vue {
  isShowAdd = false
  isShowEdit = false
  isShowCheck = false
  authStore = AuthStore

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/files/upload`
  tokenHeader = { Authorization: this.authStore.token + '' }
  page = {
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    totalPages: 0
  }

  fileList: any[] = []
  tableData: any[] = []

  uploadFile: Material = {
    content: [],
    cover: '',
    introduction: '',
    name: '',
    resourceType: undefined,
    type: MaterialType.LIFE_PRACTICE,
    id: 0,
    isPublic: false,
    createdBy: ''
  }

  mounted () {
    console.log('11=', this.tokenHeader)
    this.loadData()
  }

  tapUploadNewMaterial () {
    this.isShowAdd = true
    this.uploadFile = {
      content: [],
      cover: '',
      introduction: '',
      name: '',
      resourceType: undefined,
      type: MaterialType.LIFE_PRACTICE,
      id: 0,
      isPublic: false,
      createdBy: ''
    }
  }

  getDate (date?: string) {
    if (date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    }
    return ''
  }

  loadPageData () {
    this.loadData()
  }

  loadData (searchText = '') {
    if (searchText.length > 0) {
      this.page.currentPage = 1
    }
    service.get('/mindup/materials', {
      params: { materialType: MaterialType.LIFE_PRACTICE, materialName: searchText, pageNum: this.page.currentPage, pageSize: this.page.pageSize, isQueryAll: AuthStore.isAdmin }
    }).then((res) => {
      this.tableData = res.data.content
      this.page.totalCount = res.data.totalCount
      this.page.totalPages = res.data.totalPages
    })
  }

  createMateril () {
    console.log('this.uploadFile == ', this.uploadFile)
    service.post('/mindup/materials', this.uploadFile).then((res) => {
      ElMessage({ type: 'success', message: '添加成功' })
      this.isShowAdd = false
      this.tableData.push(res.data)
    })
  }

  updateMateril () {
    console.log('this.uploadFile == ', this.uploadFile)
    service.post('/mindup/materials/' + this.uploadFile.id, this.uploadFile).then((res) => {
      ElMessage({ type: 'success', message: '更新成功' })
      this.isShowEdit = false
      const index = this.tableData.findIndex(item => item.id === res.data.id)
      if (index !== -1) {
        this.tableData.splice(index, 1, res.data)
      }
    })
  }

  handleClickEdit (row: any) {
    this.isShowEdit = true
    this.uploadFile = row
  }

  handleClickDelete (row: any) {
    console.log(row)
    ElMessageBox({
      title: '删除素材',
      message: '删除素材后不可找回,是否确定删除?',
      showCancelButton: true,
      callback: (action: string) => {
        if (action === 'confirm') {
          service.delete('/mindup/materials/' + row.id).then((res) => {
            ElMessage({ type: 'success', message: '删除成功' })
            this.loadData()
          })
        }
      }
    })
  }

  handleImgSuccess (res: any) {
    console.log('uploadFile.cover == ', res.data.url)
    this.uploadFile.cover = res.data.url
  }

  beforeImgUpload (file: any) {
    const isJPG = (file.type.indexOf('image/') !== -1)
    const isLt2M = file.size / 1024 / 1024 < 10

    if (!isJPG) {
      ElMessage('格式错误!')
    }
    if (!isLt2M) {
      ElMessage('上传图片大小不能超过 10MB!')
    }
    return isJPG && isLt2M
  }

  handlePublic (row: any) {
    // /materials/{id}/set-or-cancel-public
    service.post('/mindup/materials/' + row.id + '/set-or-cancel-public').then((res) => {
      ElMessage({ type: 'success', message: '操作成功' })
    })
  }

  handleClickCheck (row: any) {
    this.uploadFile = row
    this.isShowCheck = true
  }
}
