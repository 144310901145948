
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Plan } from '@/models/plan'

@Options({
  components: {}
})
export default class SearchBar extends Vue {
  @Prop() placeholder = ''
  searchText = ''

  tapSearch () {
    this.$emit('search', this.searchText)
  }
}
