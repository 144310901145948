
import { Options, Vue } from 'vue-class-component'
import { ElMessage } from 'element-plus'
import { AuthStore } from '@/store/modules/auth'
import { Model, Watch } from 'vue-property-decorator'
import { Plan } from '@/models/plan'
import dayjs from 'dayjs'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

Vue.registerHooks([
  'beforeRouteLeave'
])
@Options({
  components: {}
})
export default class PlanBasic extends Vue {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/files/upload`
  authStore = AuthStore
  tokenHeader = { Authorization: this.authStore.token + '' }
  dialogVisible = false
  @Model('isSave', {
    type: String,
    default: '0'
  })
  isNeedSave = '0'

  @Model('plans', {
    type: Object,
    default: {
      name: ''
    }
  })
  plan!: Plan

  next!: NavigationGuardNext

  @Watch('isSave')
  onChangeIsSave (newVal: any) {
    console.log('isSave ==== ', newVal)
    this.isNeedSave = newVal
  }

  mounted () {
    console.log('this == ', this)
  }

  handleAvatarSuccess (res: any, file: any) {
    console.log(res)
    this.isNeedSave = '1'
    this.plan.cover = res.data.url
  }

  beforeAvatarUpload (file: any) {
    const isJPG = (file.type.indexOf('image/') !== -1)
    const isLt2M = file.size / 1024 / 1024 < 10

    if (!isJPG) {
      ElMessage('格式错误!')
    }
    if (!isLt2M) {
      ElMessage('上传图片大小不能超过 10MB!')
    }
    return isJPG && isLt2M
  }

  timeSelected (e: any) {
    console.log(e)
    this.isNeedSave = '1'
    this.plan.startTime = dayjs(e).toISOString()
    if (this.plan.planDays) {
      const endDate = dayjs(e).add(23, 'hour').add(59, 'minute').add(59, 'second').add(this.plan.planDays - 1, 'day')
      this.plan.endTime = endDate.toISOString()
    }
    console.log(this.plan.endTime)
  }

  handleChange (e: any) {
    this.isNeedSave = '1'
    const endDate = dayjs(this.plan.startTime).add(23, 'hour').add(59, 'minute').add(59, 'second').add(e - 1, 'day')
    this.plan.endTime = endDate.toISOString()
    console.log(this.plan.endTime)
  }

  input (e: any) {
    this.isNeedSave = '1'
    console.log(e)
  }

  beforeRouteLeave (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (this.isNeedSave === '1') {
      this.dialogVisible = true
      this.next = next
    } else {
      next()
    }
  }

  tapLeft () {
    this.dialogVisible = false
    this.next()
  }
}

