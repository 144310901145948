
import { Options, Vue } from 'vue-class-component'
import SearchBar from '@/components/SearchBar.vue'
import { AuthStore } from '@/store/modules/auth'
import { ElMessage, ElMessageBox } from 'element-plus'
import service from '@/utils/service'
import { Material, MaterialContent, MaterialType, MediaFile } from '@/models/material'
import moment from 'moment'
import ImageCropper from '@/components/ImageCropper.vue'

@Options({
  components: {
    SearchBar,
    ImageCropper
  }
})
export default class StandardExerciseMaterial extends Vue {
  isShowAdd = false
  isShowEdit = false
  isShowCheck = false
  isShowCropper = false
  authStore = AuthStore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/files/upload`
  tokenHeader = { Authorization: this.authStore.token + '' }
  currentFileIndex = 0
  page = {
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    totalPages: 0
  }

  mediaList: MediaFile[] = []

  tableData: any[] = []
  uploadFile: Material = {
    content: [],
    cover: '',
    introduction: '',
    name: '',
    resourceType: 'AUDIO',
    type: MaterialType.STANDARD_EXERCISE,
    id: 0,
    isPublic: false,
    createdBy: ''
  }

  editFile: Material = {
    content: [],
    cover: '',
    introduction: '',
    name: '',
    resourceType: 'AUDIO',
    type: MaterialType.STANDARD_EXERCISE,
    id: 0,
    isPublic: false,
    createdBy: ''
  }

  role () {
    return AuthStore.isAdmin
  }

  mounted () {
    this.loadData()
  }

  tapUploadNewMaterial () {
    this.isShowAdd = true
    this.uploadFile = {
      content: [],
      cover: '',
      introduction: '',
      name: '',
      resourceType: 'AUDIO',
      type: MaterialType.STANDARD_EXERCISE,
      id: 0,
      isPublic: false,
      createdBy: ''
    }
    this.mediaList = [
      {
        fileList: [],
        name: ''
      }
    ]
  }

  getDate (date?: string) {
    if (date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    }
    return ''
  }

  loadPageData () {
    this.loadData()
  }

  loadData (searchText = '') {
    if (searchText.length > 0) {
      this.page.currentPage = 1
    }
    console.log('this.authStore.currentRole = ', this.authStore.currentRole)
    console.log('this.authStore.currentRole = ', this.role())
    service.get('/mindup/materials', {
      params: { materialType: MaterialType.STANDARD_EXERCISE, materialName: searchText, pageNum: this.page.currentPage, pageSize: this.page.pageSize, isQueryAll: this.role() }
    }).then((res) => {
      this.tableData = res.data.content
    })
  }

  updateMateril () {
    console.log(this.mediaList)
    const para = this.editFile
    para.content = []
    let nameLess = false
    this.mediaList.forEach((item) => {
      if (item.fileList.length > 0 && item.fileList[0].url.length > 0) {
        // 有URL才保存
        if (item.name.length === 0) {
          nameLess = true
        }
        const arr = para.content as MaterialContent[]
        arr.push({ url: item.fileList[0].url, name: item.name })
      }
    })
    if (nameLess) {
      ElMessage({ type: 'warning', message: '请填写音频版本名称' })
      return
    }
    console.log('para ======= ', para)
    service.post('/mindup/materials/' + para.id, para).then((e) => {
      ElMessage('更新成功')
      this.isShowEdit = false
      this.loadData()
    })
  }

  createMateril () {
    const para = this.uploadFile
    this.mediaList.forEach((item) => {
      const arr = para.content as MaterialContent[]
      arr.push({ url: item.fileList[0].url, name: item.name })
    })
    console.log(para)
    service.post('/mindup/materials', para).then((e) => {
      ElMessage('添加素材成功')
      this.isShowAdd = false
      this.loadData()
    }).catch((e) => {
      ElMessage({ type: 'error', message: '添加素材失败, 请稍后再试' })
    })
  }

  tapUpload (index: number) {
    this.currentFileIndex = index
    console.log(index)
  }

  handleSuccess (res: any) {
    console.log(res)
    this.mediaList[this.currentFileIndex].fileList.splice(0, 1, res.data)
  }

  handleRemove (file: any, filelist: any) {
    console.log('file === ', file)
    const index = this.mediaList.findIndex(i => i.fileList.length > 0 && i.fileList[0].url === file.url && i.fileList[0].name === file.name)
    if (index > -1) {
      this.mediaList[index].fileList.splice(0, 1)
    }
  }

  beforeUpload (file: any) {
    const isAudio = (file.type.indexOf('audio/') !== -1)
    const isLt2M = file.size / 1024 / 1024 < 100

    if (!isAudio) {
      ElMessage('格式错误!')
    }
    if (!isLt2M) {
      ElMessage('上传音频大小不能超过 100MB!')
    }
    return isAudio && isLt2M
  }

  handleImgSuccess (res: any) {
    console.log('uploadFile.cover == ', res.data.url)
    this.uploadFile.cover = res.data.url
    this.editFile.cover = res.data.url
  }

  beforeImgUpload (file: any) {
    const isJPG = (file.type.indexOf('image/') !== -1)
    const isLt2M = file.size / 1024 / 1024 < 10

    if (!isJPG) {
      ElMessage('格式错误!')
    }
    if (!isLt2M) {
      ElMessage('上传图片大小不能超过 10MB!')
    }
    return isJPG && isLt2M
  }

  tapAdd (index: number) {
    this.mediaList.splice(index, 0, { fileList: [], name: '' })
  }

  tapDelete (index: number) {
    this.mediaList.splice(index, 1)
  }

  handleClickEdit (row: any) {
    this.editFile = row
    this.isShowEdit = true
    console.log(row)

    this.mediaList = []
    const arr = this.editFile.content as MaterialContent[]
    arr.forEach((item: MaterialContent) => {
      this.mediaList.push({
        fileList: [{
          url: item.url,
          name: item.name,
          duration: 0
        }],
        name: item.name
      })
    })
    if (this.mediaList.length === 0) {
      this.mediaList = [{
        fileList: [],
        name: ''
      }]
    }
  }

  handleClickDelete (row: any) {
    console.log(row)
    ElMessageBox({
      title: '删除素材',
      message: '删除素材后不可找回,是否确定删除?',
      showCancelButton: true,
      callback: (action: string) => {
        if (action === 'confirm') {
          service.delete('/mindup/materials/' + row.id).then((res) => {
            ElMessage({ type: 'success', message: '删除成功' })
            this.loadData()
          })
        }
      }
    })
  }

  handlePublic (row: any) {
    // /materials/{id}/set-or-cancel-public
    service.post('/mindup/materials/' + row.id + '/set-or-cancel-public').then((res) => {
      ElMessage({ type: 'success', message: '操作成功' })
    })
  }

  handleClickCheck (row: any) {
    this.editFile = row
    this.isShowCheck = true
  }
}
