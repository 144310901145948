
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { HomeStatistics } from '@/models/Statistics'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: {}
})
export default class Home extends Vue {
  homeModel: HomeStatistics = {
    accumulatePlanNum: 0,
    accumulatePlanStudentNum: 0,
    currentPlanNum: 0,
    currentPlanStudentNum: 0
  }

  get user () {
    return AuthStore.user
  }

  mounted () {
    this.loadData()
  }

  loadData () {
    service.get('/mindup/statistics/home-page-statistics').then((res) => {
      this.homeModel = res.data
    })
  }
}
