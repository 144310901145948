import axios from 'axios'
import { AuthStore } from '@/store/modules/auth'
import { ElMessage } from 'element-plus'

const service = axios.create({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  baseURL: window.VUE_APP_BASE_API,
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (AuthStore.token) {
      config.headers.Authorization = AuthStore.token + ''
    }
    console.log('url==', config.url)
    if (config.method === 'get') {
      console.log('param==', config.params)
    } else {
      console.log('param==', config.data)
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    console.table('返回数据 == ', response.data.data)
    return response.data || response
  },
  error => {
    console.log('error  ====  ', error)
    const status = error.response.status
    if (status === 401) {
      ElMessage.error('认证失败，请重新登录')
      AuthStore.removeToken()
      window.location.reload()
    } else {
      ElMessage.error(error.response.data.msg || '发生错误，请联系开发团队')
      return Promise.reject(error.response.data)
    }
  }
)

export default service
