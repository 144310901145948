
import { Options, Vue } from 'vue-class-component'
import SearchBar from '@/components/SearchBar.vue'
import service from '@/utils/service'
import { Plan, PlanStudentStatus } from '@/models/plan'
import { Model } from 'vue-property-decorator'

@Options({
  components: {
    SearchBar
  }
})
export default class PlanStudent extends Vue {
  isFormalStudent = true
  @Model('plans', {
    type: Object,
    default: {
      name: ''
    }
  })
  plan!: Plan

  studentTableData = []
  applyTableData = []
  page = {
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    totalPages: 1
  }

  applyPage = {
    currentPage: 1,
    pageSize: 2,
    totalCount: 0,
    totalPages: 1
  }

  mounted () {
    console.log('this.plan.id  == ', this.plan.id)
    console.log(this.$route.path)
    const path = this.$route.path
    const front = path.replace('/plans/', '')
    const last = front.replace('/students', '')
    console.log(last)
    this.plan.id = parseInt(last)

    this.loadData()
  }

  loadData () {
    console.log('this.plan.id  == ', this.plan.id)

    if (this.plan.id) {
      this.loadStudentData()
      this.loadApplyData()
    }
  }

  loadStudentData () {
    service.get('/mindup/plans/' + this.plan.id + '/students', {
      params: {
        pageNum: this.page.currentPage,
        pageSize: this.page.pageSize,
        planStudentStatus: PlanStudentStatus.AUDIT_PASSED
      }
    }).then((res) => {
      this.studentTableData = res.data.content
      this.page.currentPage = res.data.pageNumber
      this.page.totalPages = res.data.totalPages
      this.page.totalCount = res.data.totalCount
      console.log('this.page  == ', this.page)
    })
  }

  loadApplyData () {
    service.get('/mindup/plans/' + this.plan.id + '/students', {
      params: {
        pageNum: this.applyPage.currentPage,
        pageSize: this.applyPage.pageSize,
        planStudentStatus: PlanStudentStatus.AUDITING
      }
    }).then((res) => {
      this.applyTableData = res.data.content
      this.applyPage.currentPage = res.data.pageNumber
      this.applyPage.totalPages = res.data.totalPages
      this.applyPage.totalCount = res.data.totalCount
    })
  }

  tapApply () {
    this.isFormalStudent = false
  }

  tapStudent () {
    this.isFormalStudent = true
  }

  indexMethod (index: any) {
    if (this.isFormalStudent) {
      return index + (this.page.currentPage - 1) * this.page.pageSize + 1
    } else {
      return index + (this.applyPage.currentPage - 1) * this.applyPage.pageSize + 1
    }
  }

  handleClickDelete (planStuId: string) {
    console.log(planStuId)
    service.delete('/mindup/plans/' + this.plan.id + '/planStudent/' + planStuId + '/delete', {}).then((res) => {
      alert('移除成功')
      this.loadData()
    })
  }

  handleClickReject (stuId: string) {
    service.post('/mindup/plans/' + this.plan.id + '/students/' + stuId + '/audit', {
      auditAction: 'REJECT'
    }).then((res) => {
      alert('拒绝成功')
      this.loadData()
    })
  }

  handleClickPass (stuId: string) {
    service.post('/mindup/plans/' + this.plan.id + '/students/' + stuId + '/audit', {
      auditAction: 'PASS'
    }).then((res) => {
      alert('通过成功')
      this.loadData()
    })
  }

  tapSearchName (searchText: string) {
    console.log(searchText)
    service.get('/mindup/plans/' + this.plan.id + '/students', {
      params: {
        pageNum: 1,
        pageSize: 1000,
        planStudentStatus: PlanStudentStatus.AUDIT_PASSED,
        studentName: searchText
      }
    }).then((res) => {
      this.studentTableData = res.data.content
    })
  }

  tapSearchPhone (searchText: string) {
    console.log(searchText)
    service.get('/mindup/plans/' + this.plan.id + '/students', {
      params: {
        pageNum: 1,
        pageSize: 1000,
        planStudentStatus: PlanStudentStatus.AUDIT_PASSED,
        mobilePhone: searchText
      }
    }).then((res) => {
      this.studentTableData = res.data.content
    })
  }

  currentPageChange (e: any) {
    console.log(e)
    this.page.currentPage = e
    this.loadStudentData()
  }

  currentApplyPageChange (e: any) {
    console.log(e)
    this.applyPage.currentPage = e
    this.loadApplyData()
  }
}
