
import { Options, Vue } from 'vue-class-component'
import PlanPicker from '@/components/PlanPicker.vue'
import PlanUserPicker from '@/components/PlanUserPicker.vue'
import SummaryHeader from '@/components/SummaryHeader.vue'
import { Plan } from '@/models/plan'
import service from '@/utils/service'
import { PlanStatistics, PlanUser } from '@/models/Statistics'
import { User } from '@/models/user'
import axios from 'axios'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: {
    PlanPicker,
    PlanUserPicker,
    SummaryHeader
  }
})
export default class WeeklySummary extends Vue {
  isPickerShow = false
  isUserPickerShow = false
  currentPlan: Plan = {
    name: '',
    id: undefined,
    content: '',
    cover: '',
    endTime: '',
    introduction: '',
    isAllowRetrain: false,
    planSurveyList: [],
    saveType: '',
    startTime: '',
    publishStatus: ''
  }

  currentUser: PlanUser = {
    createTime: '',
    createdBy: 0,
    id: 0,
    mobilePhone: '',
    planId: 0,
    status: '',
    studentId: 0,
    studentName: ''
  }

  plans: PlanStatistics[] = []

  tapSelectPlan () {
    console.log('plan')
    this.isPickerShow = true
  }

  tapSelectUser () {
    this.isUserPickerShow = true
  }

  userSelectd (user: PlanUser) {
    this.currentUser = user
    if (this.currentPlan && this.currentUser && this.currentPlan.id && this.currentUser.studentId) {
      this.loadPlanData(this.currentPlan.id, this.currentUser.studentId)
    }
  }

  planSelected (plan: Plan) {
    console.log('plan == ', plan)
    this.currentPlan = plan
  }

  loadPlanData (planId: number, userId: number) {
    service.get('/mindup/statistics/weekly-practice-statistics', {
      params: {
        planId: planId,
        userId: userId
      }
    }).then((res) => {
      this.plans = res.data
    })
  }

  exportInfo () {
    const data = {
      planId: this.currentPlan.id
    }
    const url = '/mindup/statistics/weekly-practice-statistics/export'
    this.downExcelAction(url, data)
  }

  downExcelAction (url: string, data: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const baseUrl = window.VUE_APP_BASE_API
    return axios({
      url: baseUrl + url,
      method: 'get',
      data: '',
      params: data,
      transformRequest: [function (data) {
        let ret = ''
        for (const it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: AuthStore.token + ''
      },
      responseType: 'blob'
    }).then((res) => {
      const blob = new Blob([res.data], { type: 'application/vnd.ms-excel    application/x-excel;charset=utf-8' })
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      const timestamp = new Date().getTime()
      downloadElement.download = timestamp + '.xls'
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    })
  }
}
