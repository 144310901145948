export enum MaterialType {
  STANDARD_EXERCISE = 'STANDARD_EXERCISE',
  LIFE_PRACTICE = 'LIFE_PRACTICE'
}

export interface MaterialContent {
  url: string
  name: string
}

export interface Material {
  content: string | MaterialContent[]
  cover: string
  id: number
  introduction: string
  name: string
  resourceType: string | undefined
  type: MaterialType
  isPublic: boolean,
  createdBy: string
}

export interface file {
  name: string
  url: string
  duration: number
}

export interface MediaFile {
  fileList: file[]
  name: string
}
