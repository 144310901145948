
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Plan } from '@/models/plan'

@Options({
  components: {}
})
export default class Dashboard extends Vue {
  @Prop() plan!: Plan
}
