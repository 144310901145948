
import { Options, Vue } from 'vue-class-component'
import SearchBar from '@/components/SearchBar.vue'
import { Emit, Model } from 'vue-property-decorator'
import { Plan, PlanStatus, PlanStatusName } from '@/models/plan'
import service from '@/utils/service'
import dayjs from 'dayjs'
import { format } from '@/utils/date'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: {
    SearchBar
  }
})
export default class PlanPicker extends Vue {
  @Model('isShow', {
    type: Boolean,
    default: false
  })
  show!: boolean

  plans: Plan[] = []
  currentStatus = PlanStatus.PUBLISHED
  currentPlan?: Plan
  page = {
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    totalPages: 0
  }

  getStatus (status: any) {
    const findMe = PlanStatusName[status]
    return findMe
  }

  getDate (date: string) {
    const dateJs = dayjs(date)
    const str = dateJs.format('YYYY-MM-DD')
    return str
  }

  mounted () {
    this.loadData()
  }

  loadData () {
    service.get('/mindup/plans', {
      params: { pageNum: this.page.currentPage, pageSize: this.page.pageSize, planPublishStatus: this.currentStatus, isQueryAll: AuthStore.isAdmin }
    }).then((res) => {
      this.plans = res.data.content
      this.page.totalPages = res.data.totalPages
      this.page.totalCount = res.data.totalCount
      this.page.currentPage = res.data.pageNumber
      this.page.pageSize = res.data.pageSize
    })
  }

  tapRow (row: any) {
    // console.log(row)
  }

  tapTapRow (row: any) {
    // console.log(row)
  }

  currentChange (row: any) {
    console.log(row)
    this.currentPlan = row
  }

  tapConfirm () {
    if (this.currentPlan) {
      this.plan()
      this.show = false
    } else {
      alert('请选择一个计划')
    }
  }

  @Emit('plan')
  plan () {
    return this.currentPlan
  }
}
