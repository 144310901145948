
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { AuthStore } from '@/store/modules/auth'
import service from '@/utils/service'
import VueCropper, { VueCropperJsConstructor, VueCropperMethods } from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

@Options({
  components: {
    VueCropper
  }
})
export default class ImageCropper extends Vue {
  @Prop() image = ''
  @Prop() imageName = ''
  @Prop() radio = 16 / 9

  authStore = AuthStore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fileUploadPath = `${window.VUE_APP_BASE_API}/files/upload`
  tokenHeader = { Authorization: this.authStore.token + '' }
  currentImage = this.image
  currentImageName = this.imageName

  mounted () {
    console.log(this)
    const cropper = (this.$refs.cropper as VueCropperMethods)
    cropper.replace(this.image)
  }

  changeImage (response: any) {
    console.log(response)
    this.currentImage = response.data.url
    this.currentImageName = response.data.name
    const cropper = (this.$refs.cropper as VueCropperMethods)
    cropper.replace(this.currentImage)
  }

  tapRadio (ra: any) {
    console.log('tapRadio')
    const cropper = (this.$refs.cropper as VueCropperMethods)
    cropper.setAspectRatio(ra)
  }

  handleSelected () {
    console.log('handleSelected')
    const cropper = (this.$refs.cropper as VueCropperMethods)
    const data = cropper.getCroppedCanvas().toDataURL()
    console.log('handleSelected', data)
    service.post('/files/upload/base64-image', { imageValue: data }).then(res => {
      console.log(res.data)
      const url = res.data.url
      this.$emit('uploadSuccess', url)
    })
  }

  dataURItoBlob (dataURI: any, type: any) {
    const binary = atob(dataURI.split(',')[1])
    const array = []
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i))
    }
    return new Blob([new Uint8Array(array)], { type: type })
  }

  imageBeforeUpload (file: any) {
    console.log('imageBeforeUpload === ', file)
    return new Promise(resolve => {
      const reader = new FileReader()
      const image = new Image()
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const context: CanvasRenderingContext2D | null = canvas.getContext('2d')
        if (!context) {
          return
        }
        let quality
        const fileSize = file.size / 1024 / 1024
        if (fileSize < 1 && fileSize > 0.5) {
          quality = 0.6
        } else if (fileSize < 2) {
          quality = 0.5
        } else if (fileSize < 4) {
          quality = 0.4
        } else {
          quality = 0.2
        }
        const width = image.width * quality
        const height = image.height * quality
        canvas.width = width
        canvas.height = height
        context.clearRect(0, 0, width, height)
        context.drawImage(image, 0, 0, width, height)
        const dataUrl = canvas.toDataURL(file.type)
        const blobData = this.dataURItoBlob(dataUrl, file.type)
        resolve(blobData)
      }
      reader.onload = (e: any) => {
        image.src = e.target.result
      }
      reader.readAsDataURL(file)
    })
  }
}
