
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/service'
import { AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import SearchInput from '@/components/SearchInput.vue'

@Options({
  components: {
    SearchInput
  }
})
export default class UserList extends Vue {
  tableData = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    content: Array<any>()
  }

  dialogVisible = false
  currentUser: any
  roles = Array<any>()
  nameKeyword = ''
  phoneKeyword = ''

  currentRoles = Array<any>()

  beforeMount (): void {
    this.reloadPage(1)
    service.get('/uaa/roles', {
      params: {
        appCode: 'MINDUP'
      }
    }).then((res: AxiosResponse) => {
      this.roles = res.data
    })
  }

  reset () {
    this.nameKeyword = ''
    this.phoneKeyword = ''
    this.reloadPage(1)
  }

  getRoleName (role: string) {
    return this.roles.find(r => r.role === role.replace('MINDUP__', '')).roleName
  }

  handleSearch (phone: string) {
    this.phoneKeyword = phone
    this.reloadPage(1)
  }

  reloadPage (pageNumber: number) {
    service.get('/uaa/users', {
      params: {
        appCode: 'MINDUP',
        pageNum: pageNumber,
        pageSize: 10,
        name: this.nameKeyword.trim() === '' ? undefined : this.nameKeyword.trim(),
        phone: this.phoneKeyword.trim() === '' ? undefined : this.phoneKeyword.trim()
      }
    }).then((res: AxiosResponse) => {
      this.tableData = res.data
    })
  }

  submit () {
    service.post(`/uaa/users/${this.currentUser.id}/roles`, {
      appCode: 'MINDUP',
      roleIds: this.currentRoles.filter(r => r.selected).map(r => r.id)
    }).then((res: AxiosResponse<Array<any>>) => {
      this.currentUser.roles = res.data.map(r => r.role.appCode + '__' + r.role.role)
      ElMessage.success('操作成功！')
      this.dialogVisible = false
    })
  }

  handleRowClick (user: any): void {
    this.currentUser = user
    this.currentRoles = this.roles.map((role) => {
      return { ...role, selected: user.roles.findIndex((r: string) => r === `MINDUP__${role.role}`) > -1 }
    })
    this.dialogVisible = true
  }
}
