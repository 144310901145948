
import { Options, Vue } from 'vue-class-component'
import SearchBar from './SearchBar.vue'
import { Model, Watch } from 'vue-property-decorator'
import { Plan, PlanSurvey as PlanSurveys } from '@/models/plan'
import SurveyPicker from '@/components/SurveyPicker.vue'
import dayjs from 'dayjs'

@Options({
  components: {
    SearchBar,
    SurveyPicker
  }
})
export default class PlanSurvey extends Vue {
  @Model('plans', {
    type: Object,
    default: {}
  })
  plan!: Plan

  isShowSurvey = false
  isOk = true

  updated () {
    console.log('updated')
    this.update()
  }

  mounted () {
    this.update()
  }

  update () {
    this.plan.planSurveyList.forEach((item: any) => {
      if (item.timeArr && item.timeArr.length > 0) {

      } else {
        if (!item.startTime || !item.endTime) {

        } else {
          item.timeArr = []
          item.timeArr.push(dayjs(item.startTime).toDate())
          item.timeArr.push(dayjs(item.endTime).toDate())
        }
      }
    })
  }

  tapSearch (searchText: string) {
    console.log(searchText)
  }

  surveySelected (row: any) {
    if (!this.isOk) {
      return
    }
    this.isOk = false
    setTimeout(() => {
      this.isOk = true
    }, 2000)

    const item = row as PlanSurveys
    if (item.id) {
      item.surveyId = item.id
    }
    item.id = undefined
    this.plan.planSurveyList.push(row)
  }

  timeSelected (index: number) {
    const arr = this.plan.planSurveyList[index].timeArr
    if (arr && arr.length > 1) {
      this.plan.planSurveyList[index].startTime = dayjs(arr[0]).toISOString()
      const endDate = dayjs(arr[1]).add(23, 'hour').add(59, 'minute').add(59, 'second')
      this.plan.planSurveyList[index].endTime = endDate.toISOString()
    }
  }

  tapEdit (item: any) {
    console.log(item)
    this.$router.push({ name: 'SurveyDetail', params: { SurveyId: item.surveyId } })
  }

  tapDelete (index: number) {
    this.plan.planSurveyList.splice(index, 1)
  }
}
