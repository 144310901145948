
import { Options, Vue } from 'vue-class-component'
import SearchBar from '@/components/SearchBar.vue'
import { Emit, Model, Prop, Watch } from 'vue-property-decorator'
import service from '@/utils/service'
import { Plan } from '@/models/plan'
import { User } from '@/models/user'
import { PlanUser } from '@/models/Statistics'

@Options({
  components: {
    SearchBar
  }
})
export default class PlanUserPicker extends Vue {
  @Model('isShow', {
    type: Boolean,
    default: false
  })
  show!: boolean

  @Prop() plan?: Plan
  users: PlanUser[] = []
  currentUser?: PlanUser

  @Watch('plan')
  onChangePlan (newVal: Plan) {
    if (newVal) {
      this.loadData()
    }
  }

  tapRow (row: any) {
    console.log(row)
  }

  tapTapRow (row: any) {
    console.log(row)
  }

  currentChange (row: any) {
    console.log(row)
    this.currentUser = row
  }

  loadData () {
    if (this.plan && this.plan.id) {
      service.get('/mindup/plans/' + this.plan.id + '/students', {
        params: {
          planStudentStatus: 'AUDIT_PASSED'
        }
      }).then((res) => {
        this.users = res.data.content
      })
    }
  }

  tapConfirm () {
    if (this.currentUser) {
      this.user()
      this.show = false
    } else {
      alert('请选择一个用户')
    }
  }

  @Emit('user')
  user () {
    return this.currentUser
  }
}
