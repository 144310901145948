
import { Options, Vue } from 'vue-class-component'
import PlanPicker from '@/components/PlanPicker.vue'
import SummaryHeader from '@/components/SummaryHeader.vue'
import service from '@/utils/service'
import { Plan } from '@/models/plan'
import { PlanStatistics } from '@/models/Statistics'
import axios from 'axios'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: {
    PlanPicker,
    SummaryHeader
  }
})
export default class PlanSummary extends Vue {
  isPickerShow = false
  tableData = []
  currentPlan: Plan = {
    name: '',
    id: undefined,
    content: '',
    cover: '',
    endTime: '',
    introduction: '',
    isAllowRetrain: false,
    planSurveyList: [],
    saveType: '',
    startTime: '',
    publishStatus: ''
  }

  plans: PlanStatistics[] = []

  mounted () {
    //
  }

  tapSelectPlan () {
    console.log('plan')
    this.isPickerShow = true
  }

  planSelected (plan: Plan) {
    this.currentPlan = plan
    if (plan.id) {
      this.loadPlanData(plan.id)
    }
  }

  loadPlanData (planId: number) {
    service.get('/mindup/statistics/plan-statistics', {
      params: {
        planId: planId
      }
    }).then((res) => {
      this.plans = res.data
    })
  }

  exportInfo () {
    const data = {
      planId: this.currentPlan.id
    }
    const url = '/mindup/statistics/plan-statistics/export'
    this.downExcelAction(url, data)
  }

  downExcelAction (url: string, data: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const baseUrl = window.VUE_APP_BASE_API
    return axios({
      url: baseUrl + url,
      method: 'get',
      data: '',
      params: data,
      transformRequest: [function (data) {
        let ret = ''
        for (const it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: AuthStore.token + ''
      },
      responseType: 'blob'
    }).then((res) => {
      const blob = new Blob([res.data], { type: 'application/vnd.ms-excel    application/x-excel;charset=utf-8' })
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      const timestamp = new Date().getTime()
      downloadElement.download = timestamp + '.xls'
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    })
  }
}
