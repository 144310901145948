
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
  emits: ['search']
})
export default class SearchInput extends Vue {
  keyword = ''

  @Prop({ default: '输入关键字进行搜索' })
  placeholder!: string

  search () {
    this.$emit('search', this.keyword)
  }
}
