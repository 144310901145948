
import { Options, Vue } from 'vue-class-component'
import PlanCard from '@/components/PlanCard.vue'
import { Plan, PlanStatus } from '@/models/plan'
import service from '@/utils/service'
import { ElMessage, ElMessageBox } from 'element-plus'
import dayjs from 'dayjs'
import { AuthStore } from '@/store/modules/auth'

// const COVER_MAP = {
//   0: 'https://gx-static.oss-cn-beijing.aliyuncs.com/mindup/mp-pic/background-0.png',
//   1: 'https://gx-static.oss-cn-beijing.aliyuncs.com/mindup/mp-pic/background-1.png',
//   2: 'https://gx-static.oss-cn-beijing.aliyuncs.com/mindup/mp-pic/background-2.png',
//   3: 'https://gx-static.oss-cn-beijing.aliyuncs.com/mindup/mp-pic/background-3.png',
//   4: 'https://gx-static.oss-cn-beijing.aliyuncs.com/mindup/mp-pic/background-4.png',
// }

@Options({
  components: { PlanCard }
})
export default class PlanList extends Vue {
  plans: Plan[] = []
  currentStatus = PlanStatus.PUBLISHED
  isShowCopy = false
  startTime = ''
  endTime = ''
  days = 0
  copyPlanId = ''
  page = {
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
    totalPages: 0
  }

  get isAlreadyPublished () {
    return this.currentStatus === PlanStatus.PUBLISHED
  }

  mounted () {
    this.currentStatus = this.$route.query.status === 'DRAFT' ? PlanStatus.DRAFT : PlanStatus.PUBLISHED
    this.loadData()
  }

  loadData () {
    service.get('/mindup/plans', {
      params: {
        pageNum: this.page.currentPage,
        pageSize: this.page.pageSize,
        planPublishStatus: this.currentStatus,
        isQueryAll: (this.currentStatus !== PlanStatus.DRAFT) ? AuthStore.isAdmin : false,
        sort: '-createTime'
      }
    }).then((res) => {
      if (res.data.content && res.data.content.length > 0) {
        // 处理计划默认图片
        const newPlans = res.data.content.map((item: any, index: number) => {
          const coverIndex = index % 5
          item.cover = `https://gx-static.oss-cn-beijing.aliyuncs.com/mindup/mp-pic/background-${coverIndex}.png`
          return item
        })
        this.plans = newPlans
      } else {
        this.plans = []
      }
    })
  }

  deletePlan (index: number) {
    ElMessageBox({
      title: '删除计划',
      message: '删除计划后不可找回,是否确定删除?',
      showCancelButton: true,
      callback: (action: string) => {
        if (action === 'confirm') {
          service.delete('/mindup/plans/' + this.plans[index].id).then((res) => {
            ElMessage({ type: 'success', message: '删除成功' })
            this.loadData()
          })
        }
      }
    })
  }

  copyPlan (index: number) {
    console.log('copy')
    this.isShowCopy = true
    this.copyPlanId = this.plans[index].id + ''
  }

  tapCopyBtn () {
    this.copyPlanRequest(this.copyPlanId)
  }

  copyPlanRequest (planId: string) {
    if (this.startTime.length > 1) {
      const startTime = dayjs(this.startTime).toISOString()
      const endTime = dayjs(this.endTime).toISOString()
      console.log(startTime)
      console.log(endTime)
      service.post('/mindup/plans/' + planId + '/copyPlan', {
        startTime: startTime,
        endTime: endTime,
        playDays: this.days
      }).then((res) => {
        ElMessage({ type: 'success', message: '复制成功' })
        this.isShowCopy = false
        this.currentStatus = PlanStatus.DRAFT
        this.loadData()
      })
    } else {
      ElMessage({ type: 'warning', message: '请选择日期' })
    }
  }

  tapPublishStatus () {
    this.currentStatus = PlanStatus.PUBLISHED
    this.loadData()
  }

  tapDraftStatus () {
    this.currentStatus = PlanStatus.DRAFT
    this.loadData()
  }

  handleClick (tab: any, event: any) {
    console.log('name', tab.paneName)
    this.currentStatus = tab.paneName
    this.loadData()
  }

  timeSelected (e: any) {
    this.startTime = dayjs(e).toISOString()
    if (this.days) {
      const endDate = dayjs(e).add(23, 'hour').add(59, 'minute').add(59, 'second').add(this.days - 1, 'day')
      this.endTime = endDate.toISOString()
    }
  }

  handleChange (e: any) {
    const endDate = dayjs(this.startTime).add(23, 'hour').add(59, 'minute').add(59, 'second').add(e - 1, 'day')
    this.endTime = endDate.toDate().toISOString()
  }
}
