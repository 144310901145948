
import { Options, Vue } from 'vue-class-component'
import { ElMessage } from 'element-plus'
import service from '@/utils/service'
import { AuthStore } from '@/store/modules/auth'
import { AxiosResponse } from 'axios'

@Options({
  components: {}
})
export default class Login extends Vue {
  showRegister = false;
  name = '';
  username = '';
  password = '';
  confirmPassword = '';

  register (): void {
    if (this.validateRegister()) {
      service.post('uaa/public/users', {
        appCode: 'MINDUP',
        name: this.name,
        username: this.username,
        password: this.password,
        phone: this.username
      }).then(() => {
        this.showRegister = false
      })
    }
  }

  login (): void {
    if (this.validateLogin()) {
      service.post('uaa/public/tokens', {
        appCode: 'MINDUP',
        username: this.username,
        password: this.password
      }).then((res: AxiosResponse) => {
        AuthStore.setToken(res.data.token)
        const nextUrl = (this.$route.query.redirect as string) || '/'
        this.$router.push({ path: nextUrl })
      })
    }
  }

  goRegister (): void {
    this.showRegister = true
  }

  goLogin (): void {
    this.showRegister = false
  }

  validateLogin (): boolean {
    if (this.username.trim() === '') {
      ElMessage.error('手机号不可为空')
      return false
    }
    if (this.password.trim() === '') {
      ElMessage.error('密码不可为空')
      return false
    }
    return true
  }

  validateRegister (): boolean {
    if (this.username.trim() === '') {
      ElMessage.error('手机号不可为空')
      return false
    }
    if (this.name.trim() === '') {
      ElMessage.error('姓名不可为空')
      return false
    }
    if (this.password.trim().length < 6) {
      ElMessage.error('密码必须超过6位且不可有空格')
      return false
    }
    if (this.confirmPassword.trim() === '') {
      ElMessage.error('确认密码不可为空')
      return false
    }
    if (this.confirmPassword.trim() !== this.password.trim()) {
      ElMessage.error('密码与确认密码不一致')
      return false
    }
    if (!(/^1[3456789]\d{9}$/.test(this.username))) {
      ElMessage.error('手机号格式错误')
      return false
    }

    return true
  }
}
